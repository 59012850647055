
<div class="tags-list">
    <div class="tags-list__container"  *ngIf="!isLoadingResults; else loading" >
        <mat-form-field class="tags mat-mdc-form-field--fullwidth" appearance="fill" *ngIf="!checkable" >
            <h3 i18n>Klientské štítky</h3>
            <mat-chip-grid #chipGrid aria-label="Vlož tag" >

                <mat-chip-row *ngFor="let tag of (tags | tagsGroupFilter:'client')"
                                [highlighted]="false"
                                (removed)="removeTag(tag)"
                                [editable]="true" >
                    {{ tag.value }}
                <button *ngIf="removable" matChipRemove i18n-aria-label [attr.aria-label]="'odebrat štítek' + tag.value">
                <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            <input [formControl]="tagControl" 
                    placeholder="Nový štítek..."
                    i18n-placeholder
                    [matChipInputFor]="chipGrid"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addTag($event, 'client')"/>
            </mat-chip-grid>

            
        </mat-form-field>

        <div class="tags-list__listbox" >
            <h3 *ngIf="isAdmin" i18n>Klientské štítky</h3>
            <mat-chip-listbox aria-label="Výpis štítků"   selectable multiple>
                <mat-chip-option [selected]="tag.isSelected" (click)="checkTag(tag)" i18n-aria-label *ngFor="let tag of (tags | tagsGroupFilter:'client')">{{ tag.value }}</mat-chip-option>
            </mat-chip-listbox>
        </div>
    </div>
    
    <div *ngIf="isAdmin" class="tags-list__container" >
        <mat-form-field class="tags mat-mdc-form-field--fullwidth" appearance="fill" *ngIf="!checkable">
            <h3 i18n>Administrační štítky</h3>
            <mat-chip-grid #chipGrid aria-label="Vlož tag">
                <mat-chip-row *ngFor="let tag of (tags | tagsGroupFilter:'admin')"
                          [highlighted]="false"
                            (removed)="removeTag(tag)"
                            [editable]="true"
                            >
                            
                {{ tag.value }}
                <button *ngIf="removable" matChipRemove i18n-aria-label [attr.aria-label]="'odebrat štítek' + tag.value">
                <mat-icon>cancel</mat-icon>
                </button>
                </mat-chip-row>
                <input [formControl]="tagControl" 
                        placeholder="Nový štítek..."
                        i18n-placeholder
                        [matChipInputFor]="chipGrid"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="addTag($event, 'admin')"/>
                </mat-chip-grid>
        </mat-form-field>
        <div class="tags-list__listbox" *ngIf="checkable">
            <h3 i18n>Administrační štítky</h3>
            <mat-chip-listbox aria-label="Výpis štítků"  [selectable]="checkable" multiple>
                <mat-chip-option [selected]="tag.isSelected" (click)="checkTag(tag)" i18n-aria-label *ngFor="let tag of (tags | tagsGroupFilter:'admin')">{{ tag.value }}</mat-chip-option>
            </mat-chip-listbox>
        </div>
      
    </div>
</div>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
